<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="人工">
        <type-cascader v-model="filter.typeId" info="EmployeeType"></type-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column label="项目" prop="projectName" show-overflow-tooltip></el-table-column>
      <el-table-column label="名称" prop="typeName" show-overflow-tooltip></el-table-column>
      <el-table-column label="期初">
        <el-table-column label="工时" prop="qcQuantity" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.qcQuantity|thousands}}</template>
        </el-table-column>
        <el-table-column label="工时费" :width="$g.tableAmountWidth" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.qcPrice|thousands}}</template>
        </el-table-column>
        <el-table-column label="金额" prop="qcAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.qcAmount|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="本期">
        <el-table-column label="工时" prop="currentQuantity" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.currentQuantity|thousands}}</template>
        </el-table-column>
        <el-table-column label="工时费" :width="$g.tableAmountWidth" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.currentPrice|thousands}}</template>
        </el-table-column>
        <el-table-column label="金额" prop="currentAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.currentAmount|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="期末">
        <el-table-column label="工时" prop="endQuantity" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.endQuantity|thousands}}</template>
        </el-table-column>
        <el-table-column label="工时费" :width="$g.tableAmountWidth" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.endPrice|thousands}}</template>
        </el-table-column>
        <el-table-column label="金额" prop="endAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.endAmount|thousands}}</template>
        </el-table-column>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import TypeCascader from "@/components/TypeCascader";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    TypeCascader
  },
  data() {
    return {
      filter: {
        begin: this.$moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD")
      },
      data: []
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("ProjectExpense/LaborSummary", params)
        .then(r => {
          r.forEach(item => {
            item.qcAmount = this.amountFormat(item.qcAmount);
            item.qcPrice =
              item.qcQuantity == 0 ? 0 : item.qcAmount / item.qcQuantity;
            item.currentAmount = this.amountFormat(item.currentAmount);
            item.currentPrice =
              item.currentQuantity == 0
                ? 0
                : item.currentAmount / item.currentQuantity;
            item.endQuantity = item.qcQuantity + item.currentQuantity;
            item.endAmount = this.amountFormat(
              item.qcAmount + item.currentAmount
            );
            item.endPrice =
              item.endQuantity == 0 ? 0 : item.endAmount / item.endQuantity;
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.thousandsFormat(this.amountFormat(total));
        } else {
          sums[index] = "-";
        }
      });

      return [sums];
    },
    dblclickTable(row) {
      let filter = { ...this.filter };
      filter.typeId = row.expenseTypeId;
      filter.projectId = row.projectId;
      this.$store.commit("setFilterCache", {
        name: "LaborDetailList",
        filter: filter
      });
      this.$router.push({
        name: "LaborDetailList"
      });
    },
    excel() {
      let list = [];
      this.data.forEach(item => {
        list.push({ ...item });
      });

      let header = [
        ["项目", "名称", "期初", "", "", "本期", "", "", "期末", "", ""],
        [
          "",
          "",
          "工时",
          "工时费",
          "金额",
          "工时",
          "工时费",
          "金额",
          "工时",
          "工时费",
          "金额"
        ]
      ];
      let column = [
        "projectName",
        "typeName",
        "qcQuantity",
        "qcPrice",
        "qcAmount",
        "currentQuantity",
        "currentPrice",
        "currentAmount",
        "endQuantity",
        "endPrice",
        "endAmount"
      ];

      toExcel(header, column, list, "人工费用汇总", [
        {
          s: {
            c: 0, //开始列
            r: 0 //开始行
          },
          e: {
            c: 0, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 1, //开始列
            r: 0 //开始行
          },
          e: {
            c: 1, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 2, //开始列
            r: 0 //开始行
          },
          e: {
            c: 4, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 5, //开始列
            r: 0 //开始行
          },
          e: {
            c: 7, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 8, //开始列
            r: 0 //开始行
          },
          e: {
            c: 10, //结束列
            r: 0 //结束行
          }
        }
      ]);
    }
  }
};
</script>

<style>
</style>